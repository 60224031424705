.profile-card-v1 {
  border: 1px solid rgba(0,0,0,.1);
  padding: 1rem .5rem;
  border-radius: $border-radius;
  background-color: $white;
  text-align: center;

  img {
    margin-bottom: 1.25rem;
    max-width: 100px;
    border-radius: 50%;
  }

  h4 {
    margin: 0;
    font-weight: $font-weight-500;
    font-size: $font-size-base;
    line-height: 1.625rem;
  }

  span {
    opacity: $text-secondary;
  }
}

.profile-card-v2 {
  border: 1px solid rgba(0,0,0,.1);
  padding: 1rem;
  border-radius: $border-radius;
  background-color: $white;
  text-align: center;
  min-height: 220px;

  img {
    margin-bottom: 1.25rem;
    max-width: 100px;
    border-radius: 50%;
    box-shadow: 0 0 0 5px rgba(0,0,0,.1), 0 0 10px rgba(255,255,255,.2);
  }

  h4 {
    margin: 0;
    font-weight: $font-weight-500;
    font-size: 1.25rem;
    line-height: 1.625rem;
  }

  h4 + span {
    display: block;
    opacity: $text-secondary;
    margin-bottom: .875rem;
  }
}