.page_dashboard {
  padding-top: 2em;
  padding-bottom: 2em;
}
.dashboard__wrapper {
  background: #f5f6f7;
  min-height: 50em;
}
.mentor__profile_wrapper {
  min-height: 100vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 25px;
  margin-top: 10px;
  @media only screen and (max-width: 650px) {
    padding: 5px;
    margin-top: 10px;
  }
}
.profile__about {
  padding-bottom: 10px;
  // h3 {
  //   @media only screen and (max-width: 650px) {
  //     font-size: 18px;
  //   }
  // }
  // h5 {
  //   @media only screen and (max-width: 650px) {
  //     font-size: 16px;
  //   }
  // }
  img {
    border: tomato;
    height: 125px;
    width: 125px;
    border-radius: 100px;
    @media only screen and (max-width: 650px) {
      border: tomato;
      height: 60px;
      width: 60px;
      border-radius: 100px;
    }
  }
}

.profile__about__description {
  padding-top: 10px;
  .profile__about__ul {
    li {
      padding-bottom: 15px;
    }
    h5 {
      font-weight: normal;
      font-size: 18px;
      color: #333333;
    }
    p {
      font-size: 14px;
      color: #5f5f5f;
      font-weight: 400;
    }
  }
  // .mentee__description__text {
  //   max-height: 120px;
  //   overflow-y: scroll;
  // }
  .profile__about--article li a {
    font-size: 14px;
    color: #000000;
  }
  .profile__about--experience {
    h6 {
      font-size: 14px;
    }
    .ant-steps-item-title {
      font-size: 14px;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background: #a2a2a2;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background: #a2a2a2;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: #a2a2a2;
    }
  }
}

.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 0;
  height: 2px;
  background-color: #8d1bff;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #333333;
  font-weight: 500;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #8d1bff;
}
.ant-tabs {
  color: #989898;
}
// .ant-tabs-bar {
//   margin: 0 0 16px 0;
//   border-bottom: 1px solid #989898;
// }
.disabledDate {
  background-color: #ffe3e3;
  color: grey !important;
  cursor: default;
  pointer-events: none;
}
.mid-align {
  margin-left: 40%;
}
