@import "variables";
@import "ui/mixins";

// Core CSS
@import "ui/utilities";
@import "ui/type";
@import "ui/button";
@import "ui/tables-responsive";

// Components
@import "ui/badge";
@import "ui/blockquote";
@import "ui/box";
@import "ui/callout";
@import "ui/call-to-action";
@import "ui/card-blog";
@import "ui/card-box";
@import "ui/card-form";
@import "ui/card-icon";
@import "ui/card-image";
@import "ui/card-number";
@import "ui/card-portfolio";
@import "ui/card-product";
@import "ui/card-profile";
@import "ui/cover";
@import "ui/feature-callout";
@import "ui/forms";
@import "ui/hero";
@import "ui/hover";
@import "ui/hover-basic";
@import "ui/hover-link";
@import "ui/icon";
@import "ui/icon-social";
@import "ui/images";
@import "ui/list";
@import "ui/loader";
@import "ui/pricing-tables";
@import "ui/ribbon";
@import "ui/sash";
@import "ui/testimonials";
@import "ui/timeline";

@import url("https://fonts.googleapis.com/css?family=Lato:700&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Muli:700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Gloria+Hallelujah");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
//Dashboard Component
@import url("../routes/dashboard/components/style.module.scss");

body {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.brand img {
  vertical-align: top;
}

h4,
h5 {
  font-family: "Lato", sans-serif;
}

// Heading Fonts
h5,
.h5 {
  font-size: 0.975rem;
}

.bg-info {
  background-color: #fff !important;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
}
// Header
.app-header .bg-white,
.app-header .bg-warning {
  box-shadow: 0px 3px 10px #00000029;
}
.app-header .bg-info a,
.app-header .bg-info a .ant-badge i,
.app-sidenav .bg-info a {
  color: dimgray;
}
// logo for homepage
.form-card img {
  height: 40px;
  width: 200px;
  margin: 20px;
}
.form-card .form-card__img {
  border-radius: 0;
}
// notifications
.app-header-notifications .ant-tabs-nav .ant-tabs-tab {
  padding: 1rem;
  border-bottom: none;
}
.app-header-inner .list-inline-item .list-notification-icon {
  font-size: 21px;
  padding: 4px;
}
.app-header-inner .list-inline-item .ant-badge .ant-badge-count {
  font-size: 13px;
  font-weight: 300;
  padding: 0 4px;
}
.ant-tabs-ink-bar {
  background-color: transparent;
}
.ant-avatar-lg {
  height: 48px;
  width: 48px;
}
.avatar-text {
  width: 119px;
  height: 30px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  color: #707070;
}
// #app-content {
//   padding: 20px;
// }
.sidenav-content {
  border-right: none;
}
main {
  padding-bottom: 1px;
}

.profile-box-detail {
  min-height: 450px;
  // border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  // border-radius: 0.375rem;
  background-color: #fff;
  & h4 {
    font-size: 16px;
  }
}
.ant-collapse-header {
  font-weight: 600;
  font-size: 16px;
}
.profile-box-detail-help {
  min-height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.375rem;
  background-color: #fff;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
  margin-left: 20px;
}

// Mentee Tab

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
  overflow-wrap: break-word;
  background: #fff;
  color: #808080;
}
.ant-input-affix-wrapper {
  border: 0 !important;
}

//TYpography
h3 {
  margin-right: 15px;
  font-weight: 600;
  color: #242424;
  font-size: 28px;
}
.tab__wrapper {
  background-color: #fff;
  min-height: 500px;
  padding: 10px;
  padding-left: 20px;
  border-radius: 10px;
}
.m_tab__wrapper {
  background-color: #fff;
  min-height: 500px;
  // padding: 0 10px;
  border-radius: 10px;
  @media screen and (max-width: 735px) {
    padding: 2px;
  }
}

.content__area {
  padding: 2px;
  #newStickyNote {
    user-select: none;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    border: 15px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    color: rgba(0, 0, 0, 0.1);
    font: 220px "Helvetica", sans-serif;
    line-height: 185px;
  }
  #newStickyNote:hover {
    border-color: rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.2);
  }
  #newStickyNote,
  textarea {
    float: left;
    padding: 15px 15px 40px;
    margin: 0 20px 20px 0;
    width: 250px;
    height: 250px;
  }
  TextArea {
    font: 18px "Gloria Hallelujah", cursive;
    line-height: 1.5;
    border: 0;
    border-radius: 3px;
    background: linear-gradient(#f9efaf, #f7e98d);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.5s ease;
  }
  TextArea:hover {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  }
  TextArea:focus {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
    outline: none;
  }
}

// Message Tab

.message__container {
  $white: #f5f5f5;
  $dark: #2c3e50;
  $lighter: #32465a;
  $lightest: #496886;
  $light: #435f7a;
  $red: #e74c3c;
  $green: #2ecc71;
  $yellow: #f1c40f;
  $gray: #95a5a6;
  $break: 735px;
  $break-big: 900px;

  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 100vh;
  // background: #27ae60;
  font-family: "proxima-nova", "Source Sans Pro", sans-serif;
  font-size: 1em;
  letter-spacing: 0.1px;
  color: $lighter;
  text-rendering: optimizeLegibility;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;

  @mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
  }

  #frame {
    width: 98%;
    min-width: 360px;
    // max-width: 1000px;
    height: 92vh;
    min-height: 300px;
    max-height: 720px;
    background: #fff;
    border-right: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    // border-left: 1px solid #d1d1d1;

    @media screen and (max-width: 360px) {
      width: 100%;
      height: 100vh;
    }

    #sidepanel {
      float: left;
      min-width: 280px;
      max-width: 340px;
      width: 40%;
      height: 100%;
      background: #fff;
      color: $dark;
      overflow: hidden;
      border-right: 1px solid #d1d1d1;
      position: relative;
      @media screen and (max-width: $break) {
        width: 58px;
        min-width: 58px;
      }

      #profile {
        width: 80%;
        margin: 25px auto;
        @media screen and (max-width: $break) {
          width: 100%;
          margin: 0 auto;
          padding: 5px 0 0 0;
          background: $dark;
        }

        &.expanded {
          .wrap {
            height: 210px;
            line-height: initial;

            p {
              margin-top: 20px;
            }

            i.expand-button {
              -moz-transform: scaleY(-1);
              -o-transform: scaleY(-1);
              -webkit-transform: scaleY(-1);
              transform: scaleY(-1);
              filter: FlipH;
              -ms-filter: "FlipH";
            }
          }
        }

        .wrap {
          height: 60px;
          line-height: 60px;
          overflow: hidden;
          @include transition(0.3s height ease);
          @media screen and (max-width: $break) {
            height: 55px;
          }

          img {
            width: 50px;
            border-radius: 50%;
            padding: 3px;
            border: 2px solid $red;
            height: auto;
            float: left;
            cursor: pointer;
            @include transition(0.3s border ease);
            @media screen and (max-width: $break) {
              width: 40px;
              margin-left: 4px;
            }

            &.online {
              border: 2px solid $green;
            }

            &.away {
              border: 2px solid $yellow;
            }

            &.busy {
              border: 2px solid $red;
            }

            &.offline {
              border: 2px solid $gray;
            }
          }

          p {
            float: left;
            margin-left: 15px;
            @media screen and (max-width: $break) {
              display: none;
            }
          }

          i.expand-button {
            float: right;
            margin-top: 23px;
            font-size: 0.8em;
            cursor: pointer;
            color: $light;
            @media screen and (max-width: $break) {
              display: none;
            }
          }

          #status-options {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            width: 150px;
            margin: 70px 0 0 0;
            border-radius: 6px;
            z-index: 99;
            line-height: initial;
            background: $light;
            @include transition(0.3s all ease);
            @media screen and (max-width: $break) {
              width: 58px;
              margin-top: 57px;
            }

            &.active {
              opacity: 1;
              visibility: visible;
              margin: 75px 0 0 0;
              @media screen and (max-width: $break) {
                margin-top: 62px;
              }
            }

            &:before {
              content: "";
              position: absolute;
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 8px solid $light;
              margin: -8px 0 0 24px;
              @media screen and (max-width: $break) {
                margin-left: 23px;
              }
            }

            ul {
              overflow: hidden;
              border-radius: 6px;
              li {
                padding: 15px 0 30px 18px;
                display: block;
                cursor: pointer;
                @media screen and (max-width: $break) {
                  padding: 15px 0 35px 22px;
                }

                &:hover {
                  background: $lightest;
                }

                span.status-circle {
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  margin: 5px 0 0 0;
                  @media screen and (max-width: $break) {
                    width: 14px;
                    height: 14px;
                  }

                  &:before {
                    content: "";
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    margin: -3px 0 0 -3px;
                    background: transparent;
                    border-radius: 50%;
                    z-index: 0;
                    @media screen and (max-width: $break) {
                      height: 18px;
                      width: 18px;
                    }
                  }
                }

                p {
                  padding-left: 12px;
                  @media screen and (max-width: $break) {
                    display: none;
                  }
                }

                &#status-online {
                  span.status-circle {
                    background: $green;
                  }

                  &.active {
                    span.status-circle {
                      &:before {
                        border: 1px solid $green;
                      }
                    }
                  }
                }

                &#status-away {
                  span.status-circle {
                    background: $yellow;
                  }

                  &.active {
                    span.status-circle {
                      &:before {
                        border: 1px solid $yellow;
                      }
                    }
                  }
                }

                &#status-busy {
                  span.status-circle {
                    background: $red;
                  }

                  &.active {
                    span.status-circle {
                      &:before {
                        border: 1px solid $red;
                      }
                    }
                  }
                }

                &#status-offline {
                  span.status-circle {
                    background: $gray;
                  }

                  &.active {
                    span.status-circle {
                      &:before {
                        border: 1px solid $gray;
                      }
                    }
                  }
                }
              }
            }
          }

          #expanded {
            padding: 100px 0 0 0;
            display: block;
            line-height: initial !important;

            label {
              float: left;
              clear: both;
              margin: 0 8px 5px 0;
              padding: 5px 0;
            }

            input {
              border: none;
              margin-bottom: 6px;
              background: $white;
              border-radius: 3px;
              color: $lighter;
              padding: 7px;
              width: calc(100% - 43px);

              &:focus {
                outline: none;
                background: $light;
              }
            }
          }
        }
      }

      #search {
        // border-top: 1px solid $lighter;
        // border-bottom: 1px solid $lighter;
        font-weight: 300;
        padding: 10px;

        @media screen and (max-width: $break) {
          display: none;
        }

        label {
          position: absolute;
          margin: 10px 0 0 20px;
        }

        input {
          font-family: "proxima-nova", "Source Sans Pro", sans-serif;
          padding: 6px 0 6px 40px;
          width: calc(100% - 0px);
          border: none;
          background: #fff;
          color: #a5a3a3;
          outline: none;
          border-radius: 25px;
          border: 1px solid #d1d1d1;
          // &:focus {
          //   outline: none;
          //   background: $light;
          // }

          &::-webkit-input-placeholder {
            color: #a5a3a3;
          }
          &::-moz-placeholder {
            color: #a5a3a3;
          }
          &:-ms-input-placeholder {
            color: #a5a3a3;
          }
          &:-moz-placeholder {
            color: #a5a3a3;
          }
        }
      }

      #contacts {
        height: calc(100% - 100px);
        overflow-y: scroll;
        overflow-x: hidden;
        @media screen and (max-width: $break) {
          height: calc(100% - 149px);
          overflow-y: scroll;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        &.expanded {
          height: calc(100% - 334px);
        }

        &::-webkit-scrollbar {
          width: 8px;
          background: rgba(0, 0, 0, 0);
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.3);
        }

        ul {
          list-style: none;
          padding-left: initial;
          li.contact {
            position: relative;
            padding: 10px 0 15px 0;
            font-size: 0.9em;
            background: #fff;
            cursor: pointer;
            @media screen and (max-width: $break) {
              padding: 6px 0 46px 8px;
            }

            &:hover {
              color: $lighter;
              background: $white;
            }

            &.active {
              background: #fff;
              border-right: 5px solid $light;

              span.contact-status {
                border: 2px solid $lighter !important;
              }
            }

            .wrap {
              width: 92%;
              margin: 0 auto;
              position: relative;
              @media screen and (max-width: $break) {
                width: 100%;
              }

              span {
                position: absolute;
                left: 0;
                margin: -2px 0 0 -2px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 2px solid $dark;
                background: $gray;

                &.online {
                  background: $green;
                }

                &.away {
                  background: $yellow;
                }

                &.busy {
                  background: $red;
                }
              }

              img {
                width: 40px;
                border-radius: 50%;
                float: left;
                margin-right: 10px;
                @media screen and (max-width: $break) {
                  margin-right: 0px;
                }
              }

              .meta {
                padding: 5px 0 0 0;
                @media screen and (max-width: $break) {
                  display: none;
                }

                .name {
                  font-weight: 600;
                }

                .preview {
                  margin: 5px 0 0 0;
                  padding: 0 0 1px;
                  font-weight: 400;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  @include transition(1s all ease);

                  span {
                    position: initial;
                    border-radius: initial;
                    background: none;
                    border: none;
                    padding: 0 2px 0 0;
                    margin: 0 0 0 1px;
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }
      }

      #bottom-bar {
        position: absolute;
        width: 100%;
        bottom: 0;

        button {
          float: left;
          border: none;
          width: 100%;
          padding: 10px 0;
          background: $lighter;
          color: $white;
          cursor: pointer;
          font-size: 0.85em;
          font-family: "proxima-nova", "Source Sans Pro", sans-serif;
          @media screen and (max-width: $break) {
            float: none;
            width: 100%;
            padding: 15px 0;
          }

          &:focus {
            outline: none;
          }

          &:nth-child(1) {
            border-right: 1px solid $dark;
            @media screen and (max-width: $break) {
              border-right: none;
              border-bottom: 1px solid $dark;
            }
          }

          &:hover {
            background: $light;
          }

          i {
            margin-right: 3px;
            font-size: 1em;
            @media screen and (max-width: $break) {
              font-size: 1.3em;
            }
          }

          span {
            @media screen and (max-width: $break) {
              display: none;
            }
          }
        }
      }
    }

    .content {
      float: right;
      width: 60%;
      height: 100%;
      overflow: hidden;
      position: relative;
      background: #ebebeb;
      @media screen and (max-width: $break) {
        width: calc(100% - 58px);
        min-width: 300px !important;
      }
      @media screen and (min-width: $break-big) {
        width: calc(100% - 340px);
      }

      .contact-profile {
        width: 100%;
        height: 60px;
        line-height: 60px;
        background: #fff;
        border-bottom: 1px solid #d1d1d1;

        img {
          width: 40px;
          border-radius: 50%;
          float: left;
          margin: 9px 12px 0 9px;
        }

        p {
          float: left;
        }

        .social-media {
          float: right;

          i {
            margin-left: 14px;
            cursor: pointer;

            &:nth-last-child(1) {
              margin-right: 20px;
            }

            &:hover {
              color: $light;
            }
          }
        }
      }

      .messages {
        height: auto;
        min-height: calc(100% - 93px);
        max-height: calc(100% - 93px);
        overflow-y: scroll;
        overflow-x: hidden;
        @media screen and (max-width: $break) {
          max-height: calc(100% - 105px);
        }

        &::-webkit-scrollbar {
          width: 8px;
          background: rgba(0, 0, 0, 0);
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.3);
        }

        ul {
          li {
            display: inline-block;
            clear: both;
            // float: left;
            margin: 15px 15px 5px 15px;
            width: calc(100% - 25px);
            font-size: 0.9em;

            &:nth-last-child(1) {
              margin-bottom: 20px;
            }

            &.sent {
              img {
                margin: 6px 8px 0 0;
              }

              p {
                background: $light;
                color: $white;
              }
            }

            &.replies {
              img {
                float: right;
                margin: 6px 0 0 8px;
              }

              p {
                background: $white;
                float: right;
              }
            }

            img {
              width: 22px;
              border-radius: 50%;
              float: left;
            }

            p {
              display: inline-block;
              padding: 10px 15px;
              border-radius: 20px;
              max-width: 205px;
              line-height: 130%;
              @media screen and (min-width: $break) {
                max-width: 300px;
              }
            }
          }
        }
      }

      .message-input {
        position: absolute;
        // bottom: 0;
        width: 100%;
        z-index: 99;

        .wrap {
          position: relative;

          input {
            font-family: "proxima-nova", "Source Sans Pro", sans-serif;
            float: left;
            border: none;
            width: calc(100% - 50px);
            // padding: 12px 35px 8px 8px;
            padding: 0px 32px 14px 8px;
            // font-size: 0.8em;
            color: $lighter;
            @media screen and (max-width: $break) {
              padding: 19px 32px 10px 8px;
            }

            &:focus {
              outline: none;
            }
          }

          .attachment {
            position: absolute;
            right: 60px;
            z-index: 4;
            margin-top: 10px;
            font-size: 1.1em;
            color: $light;
            opacity: 0.5;
            cursor: pointer;
            @media screen and (max-width: $break) {
              margin-top: 17px;
              right: 65px;
            }

            &:hover {
              opacity: 1;
            }
          }

          button {
            float: right;
            border: none;
            width: 50px;
            padding: 8px 0;
            cursor: pointer;
            background: #fff;
            color: $white;
            @media screen and (max-width: $break) {
              padding: 16px 0;
            }

            // &:hover {
            //   background: $light;
            // }

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}

.calender__wrapper {
  .title__wrapper {
    padding: 20px;
    p {
      color: #989898;
    }
    h3 {
      margin-bottom: 0px;
      // font-family: "Muli", serif;
    }
  }
  .content__area {
    padding: 12px;
    overflow: auto;
  }
  .calender__dates {
    margin-bottom: 30px;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    .wrap {
      display: inline-block;
      padding: 12px 12px;
      text-align: center;
      width: 60px;
      height: 75px;
      border-radius: 4px;
    }
    .active {
      color: #fff;
      background: #6e4fff;
    }
    // padding: 20px 0;
    display: flex;
    // flex-direction: column;
    .date {
      color: #070707;
      font-weight: 600;
    }
    .day {
      color: #989898;
    }
    .active {
      color: #fff;
    }
  }
}

#sidebar__toggle {
  display: none;
}
.customizer-close {
  display: none;
  cursor: pointer;
}
// Mobile device media queries
@media only screen and (max-width: 650px) {
  .ant-modal {
    width: 98% !important;
  }
  .brand img {
    height: 65px !important;
    margin-top: 10px;
  }
  .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin-right: 2px;
  }
  #sidebar__toggle {
    display: flex;
    padding: 10px 0 0 10px;
    cursor: pointer;
    margin-top: -35px;
    margin-left: 15px;
  }
  .customizer-close {
    display: inline-block;
    cursor: pointer;
  }
  // Typography
  h3 {
    margin-right: 15px;
    font-size: 22px;
  }

  // Notes
  .note__wrapper .ant-input-affix-wrapper {
    max-width: 220px;
  }
  .lazur-bg {
    // height: '250px',
    //                 width: '250px',
    //                 padding: '1em',
    //                 margin: '10px',
    //                 boxShadow: 'rgba(3, 5, 23, 0.3) 0px 0px 5px',

    height: 250px;
    padding: 0.5em;
    box-shadow: rgba(3, 5, 23, 0.3) 0px 0px 5px;
    // width: 25%;
  }
  // Recruiter
  .recruiter__input__wrapper .ant-input-affix-wrapper {
    max-width: 160px !important;
  }

  // Message
  #sidepanel h3,
  #sidepanel p {
    display: none;
  }
}

// Mentor Profile Modal
.ant-form-item {
  margin-bottom: 18px;
}
.ant-radio-button-wrapper {
  // border-left: 1px solid #d9d9d9;
  // border-radius: 6px 0 0 6px;
  margin-right: 10px;
}
.ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper:last-child {
  // border-left: 1px solid #d9d9d9;
  border-radius: 0;
}
.ant-form-item-label > label {
  color: #1d0f0f;
  font-size: 16px;
  font-weight: 600;
}
.ant-form-item-label {
  text-align: left;
}
.ant-form-item-label > label::after {
  content: "";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 18px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
// .ant-form-item-control span{color: #707070; font-size: 18px;}
.ant-input,
.ant-input-number,
.ant-select-selection--single,
.ant-select-selection--multiple {
  // height: 33px;
  // padding: 2px 2px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-upload-list {
  display: none;
}
.steps-action .ant-btn,
.steps-action .ant-btn .ant-btn-primary {
  width: 120px;
  height: 42px;
  font-size: 16px;
}
.ant-form-item-control span {
  font-size: 14px;
  font-weight: 600;
}
.demo-style-steps .steps-content {
  margin-top: 16px;
  border: none;
  border-radius: 6px;
  background-color: transparent !important;
  min-height: 200px;
  padding-top: 20px !important;
  border: none !important;
  text-align: initial !important;
  padding-bottom: 30px !important;
}
.demo-style-steps .steps-content {
  text-align: left !important;
  padding: 5px;
}
.demo-style-steps .steps-action {
  float: right;
}
.post-steps .ant-steps-item-title::after {
  display: none;
}
.post-steps .ant-steps-item-container {
  outline: none;
  padding: 10px;
}
.post-steps .ant-steps-item-process .ant-steps-item-icon {
  background: #4fc752;
  border-color: transparent;
  float: right;
}
.trainer-profile .post-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #cbcbcc;
  border-color: #cbcbcc;
  float: right;
}
.post-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #ffffff;
}
.post-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  color: #d80075;
}
.demo-style-steps .steps-action {
  margin-top: -34px !important;
}
.post-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #cbcbcc;
}

.post-steps .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0px;
  border: 1px #cbcbcc solid;
  border-radius: 1px;
  width: 50%;
}
.post-steps .ant-steps-item-active {
  border: 2px solid #d80075 !important;
}

.ant-upload-picture-card-wrapper {
  width: auto;
}

// Upload Image in Profile

.profile__about .avatar-upload {
  position: relative;
  max-width: 145px;
  margin: 50px auto;
  .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
    input {
      display: none;
      + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: #f1f1f1;
          border-color: #d6d6d6;
        }
        &:after {
          content: "\f040";
          font-family: "FontAwesome";
          color: #757575;
          position: absolute;
          top: 2px;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto;
        }
      }
    }
  }
  .avatar-preview {
    width: 125px;
    height: 125px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    > div {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 140px;
  height: 140px;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 80px;
}
.mentee-profile-modal {
  .emp-profile {
    padding: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 0.5rem;
    background: #fff;
  }
  .profile-img {
    text-align: center;
  }
  .profile-img img {
    width: 70%;
    height: 200px;
  }
  .profile-img .file {
    position: relative;
    overflow: hidden;
    margin-top: -20%;
    width: 70%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
  }
  .profile-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
  }
  .profile-head h5 {
    color: #333;
  }
  // .profile-head h6 {
  //   color: #0062cc;
  // }
  .profile-edit-btn {
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    color: #6c757d;
    cursor: pointer;
  }
  .proile-rating {
    font-size: 12px;
    color: #818182;
    margin-top: 5%;
  }
  .proile-rating span {
    color: #495057;
    font-size: 15px;
    font-weight: 600;
  }
  .profile-head .nav-tabs {
    margin-bottom: 5%;
  }
  .profile-head .nav-tabs .nav-link {
    font-weight: 600;
    border: none;
  }
  .profile-head .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 2px solid #0062cc;
  }
  .profile-work {
    padding: 14%;
    margin-top: -15%;
  }
  .profile-work p {
    font-size: 12px;
    color: #818182;
    font-weight: 600;
    margin-top: 10%;
  }
  .profile-work a {
    text-decoration: none;
    color: #495057;
    font-weight: 600;
    font-size: 14px;
  }
  .profile-work ul {
    list-style: none;
  }
  .profile-tab label {
    font-weight: 600;
  }
  .profile-tab p {
    font-weight: 600;
    color: #0062cc;
  }
}

// Mentor Public Profile
.employee-profile-modal {
  // .main-section {
  //   border: 1px solid red;
  // }
  .image-section {
    background: linear-gradient(286deg, #64fefc 0%, #40a1ff 52%, #9c00ff 100%);
    height: 200px;
  }
  // .image-section img {
  //   width: 100%;
  //   height: 250px;
  //   position: relative;
  // }
  .user-image {
    position: absolute;
    margin-top: -50px;
  }
  // .user-left-part {
  //   margin: 0px;
  // }
  .user-image img {
    width: 100px;
    height: 100px;
  }
  .user-profil-part {
    padding-bottom: 30px;
    // background-color: #fafafa;
  }
  .follow {
    margin-top: 70px;
  }
  .user-detail-row {
    margin: 0px;
  }
  .user-detail-section2 p {
    font-size: 12px;
    padding: 0px;
    margin: 0px;
  }
  .user-left-part {
    background: #fff;
  }
  .user-detail-section2 {
    margin-top: 10px;
  }
  // .user-detail-section2 span {
  //   color: #7cbbc3;
  //   font-size: 20px;
  // }
  .user-detail-section2 small {
    font-size: 12px;
    color: #d3a86a;
  }
  .profile-right-section {
    padding: 20px 0px 10px 15px;
    background-color: #ffffff;
  }
  .profile-right-section-row {
    margin: 0px;
  }
  .profile-header-section1 h1 {
    font-size: 25px;
    margin: 0px;
  }
  .profile-header-section1 h5 {
    color: #0062cc;
  }
  .req-btn {
    height: 30px;
    font-size: 12px;
  }
  .profile-tag {
    padding: 10px;
    border: 1px solid #f6f6f6;
  }
  .profile-tag p {
    font-size: 12px;
    color: black;
  }
  .profile-tag i {
    color: #adadad;
    font-size: 20px;
  }
  .image-right-part {
    background-color: #fcfcfc;
    margin: 0px;
    padding: 5px;
  }
  .img-main-rightPart {
    background-color: #fcfcfc;
    margin-top: auto;
  }
  .image-right-detail {
    padding: 0px;
  }
  .image-right-detail p {
    font-size: 12px;
  }
  .image-right-detail a:hover {
    text-decoration: none;
  }
  .image-right img {
    width: 100%;
  }
  .image-right-detail-section2 {
    margin: 0px;
  }
  .image-right-detail-section2 p {
    color: #38acdf;
    margin: 0px;
  }
  .image-right-detail-section2 span {
    color: #7f7f7f;
  }

  .nav-link {
    font-size: 1.2em;
  }
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 315px;
}

.ant-select-selection--multiple .ant-select-selection__rendered {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 1180px) and (min-width: 992px) {
  .update-profile-modal,
  .schedule-event-modal {
    width: 65% !important;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1180px) {
  .update-profile-modal,
  .schedule-event-modal {
    width: 60% !important;
  }
}
@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  .post-steps .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    width: 100%;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item:not(:last-child)
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    display: none;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    display: none;
  }
}
@media only screen and (max-width: 650px) {
  .profile__about img {
    height: 125px !important;
  }
  .ant-steps-dot .ant-steps-item-content,
  .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    padding-left: 10px;
  }
}
